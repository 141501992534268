import {
  SettingOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  FolderViewOutlined,
  ScheduleOutlined,
  FileSyncOutlined,
  FileMarkdownOutlined,
  ApartmentOutlined,
  BankOutlined,
  SlidersOutlined,
  CloudServerOutlined,
  SnippetsOutlined,
  EyeOutlined,
  IdcardOutlined,
  SafetyCertificateOutlined,
  UsergroupAddOutlined,
  FieldTimeOutlined,
  CalendarOutlined,
  AuditOutlined,
  CheckCircleOutlined,
  BlockOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "../configs/AppConfig";

const componentsNavTree = [
  {
    key: "general-settings",
    path: `${APP_PREFIX_PATH}/`,
    title: "sidenav.general-settings",
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "compliance-master",
        path: `${APP_PREFIX_PATH}/compliance-master`,
        title: "sidenav.compliance-master",
        icon: FileMarkdownOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "matrics",
        path: `${APP_PREFIX_PATH}/matrics`,
        title: "sidenav.matrics",
        icon: SlidersOutlined,
        breadcrumb: true,
        active: true,
        submenu: [
          {
            key: "Dashboards",
            path: `${APP_PREFIX_PATH}/matrices/dashboard`,
            title: "sidenav.matrics-dashboard",
            icon: EyeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "Reports",
            path: `${APP_PREFIX_PATH}/matrices/reports`,
            title: "sidenav.matrics-reports",
            icon: SnippetsOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "my-compliance-view",
        path: `${APP_PREFIX_PATH}/my-compliance-view`,
        title: "sidenav.my-compliance-view",
        icon: FolderViewOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "myCompTime",
            path: `${APP_PREFIX_PATH}/my-compliance-view/time`,
            title: "sidenav.my-compliance-view-time",
            icon: FieldTimeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "myCompEvent",
            path: `${APP_PREFIX_PATH}/my-compliance-view/events`,
            title: "sidenav.my-compliance-view-events",
            icon: CalendarOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "compliance-view",
        path: `${APP_PREFIX_PATH}/compliance-calender-view`,
        title: "sidenav.compliance-view-task",
        icon: IdcardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "auditor-action",
        path: `${APP_PREFIX_PATH}/auditing-action`,
        title: "sidenav.auditor-action",
        icon: AuditOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "compliance-approval",
        path: `${APP_PREFIX_PATH}/compliance-approval`,
        title: "sidenav.compliance-approval",
        icon: CheckCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "events",
        path: `${APP_PREFIX_PATH}/events`,
        title: "sidenav.events",
        icon: CalendarOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "Certificates",
        path: `${APP_PREFIX_PATH}/matrices/certificate`,
        title: "sidenav.matrics-certificates",
        icon: SafetyCertificateOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "due-delegation",
        path: `${APP_PREFIX_PATH}/due-delegation`,
        title: "sidenav.due-delegation",
        icon: UserAddOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "dueDelegationTime",
            path: `${APP_PREFIX_PATH}/due-delegation/time`,
            title: "sidenav.due-delegation-time",
            icon: FieldTimeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "dueDelegationEvent",
            path: `${APP_PREFIX_PATH}/due-delegation/events`,
            title: "sidenav.due-delegation-events",
            icon: CalendarOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "change-due-date",
        path: `${APP_PREFIX_PATH}/change-due-date`,
        title: "sidenav.change-due-date",
        icon: CalendarOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "dueDateTime",
            path: `${APP_PREFIX_PATH}/change-due-date/time`,
            title: "sidenav.change-due-date-time",
            icon: FieldTimeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "dueDateEvent",
            path: `${APP_PREFIX_PATH}/change-due-date/events`,
            title: "sidenav.change-due-date-events",
            icon: CalendarOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "dueDateBulk",
            path: `${APP_PREFIX_PATH}/change-due-date/bulk`,
            title: "sidenav.change-due-date-bulk",
            icon: BlockOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    key: "application-settings",
    path: `${APP_PREFIX_PATH}/`,
    title: "sidenav.applicaiton-settings",
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "settings",
        path: `${APP_PREFIX_PATH}/`,
        title: "sidenav.settings",
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "compliance-admin",
            path: `${APP_PREFIX_PATH}/compliance-admin`,
            title: "sidenav.compliance-admin",
            icon: UserSwitchOutlined,
            breadcrumb: true,
            submenu: [],
          },

          {
            key: "company-management",
            path: `${APP_PREFIX_PATH}/company-management`,
            title: "sidenav.company-management",
            icon: BankOutlined,
            breadcrumb: true,
            submenu: [],
          },

          {
            key: "user-management",
            path: `${APP_PREFIX_PATH}/user-management`,
            title: "sidenav.user-management",
            icon: UserAddOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    key: "client-settings",
    path: `${APP_PREFIX_PATH}/`,
    title: "sidenav.client-settings",
    icon: ScheduleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "company-settings",
        path: `${APP_PREFIX_PATH}/`,
        title: "sidenav.company-settings",
        icon: CloudServerOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "project-management",
            path: `${APP_PREFIX_PATH}/division-management`,
            title: "sidenav.project-management",
            icon: ApartmentOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "auditor-management",
            path: `${APP_PREFIX_PATH}/auditor-management`,
            title: "sidenav.auditor-management",
            icon: AuditOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "company-compliance-mapping",
            path: `${APP_PREFIX_PATH}/company-compliance`,
            title: "sidenav.company-compliance-mapping",
            icon: FileSyncOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "division-compliance",
            path: `${APP_PREFIX_PATH}/division-compliance`,
            title: "sidenav.division-compliance",
            icon: UsergroupAddOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];
var nav = [...componentsNavTree];

const navigationConfig = [...nav];

export default navigationConfig;
