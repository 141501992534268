import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Layout, Select, Tooltip, Modal, Col, Row, TreeSelect } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import Logo from "./Logo";
// import NavNotification from './NavNotification';
import NavProfile from "./NavProfile";

import {
  toggleCollapsedNav,
  onMobileNavToggle,
} from "../../redux/actions/Theme";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "../../constants/ThemeConstant";
import utils from "../../utils";
import { Link, useHistory } from "react-router-dom";
import { listProjectsTop } from "../../apis/dashboard/project";
import {
  getLoggedinRole,
  listApprovaltask,
  listComplianceForUser,
  listEventTask,
  listTaskData,
} from "../../apis/dashboard/userLevelCompliance";
import store from "../../redux/store";
import {
  TRIGGER_API,
  TRIGGER_API_2,
  R_TRIGGER_API_1,
  R_TRIGGER_API_2,
  R_TRIGGER_API_3,
  R_TRIGGER_API_4,
  R_TRIGGER_API_5,
  R_TRIGGER_API_6,
  R_TRIGGER_API_7,
  R_TRIGGER_API_8,
  R_TRIGGER_API_9,
  R_TRIGGER_API_10,
  SET_DIVISION_ROLE,
  SET_COMPANY_ROLE,
  TRIGGER_DASHBOARD_API,
} from "../../actions/types";
import { tableRange } from "views/app-views/DynamicFilter/columns";
import navigationConfig from "../../configs/NavigationConfig";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import IntlMessage from "components/util-components/IntlMessage";
const { Option } = Select;
const { Header } = Layout;
var firstTime = true;
var listOFlink = [
  "/app/company-management",
  "/app/compliance-admin",
  "/app/user-management",
  "/app/division-management",
  "/app/company-compliance",
  "/app/division-compliance",
  "/app/compliance-mapping",
  "/app/compliance-detailed-view",
  "/app/auditor-management",
];

const listOfSideNavEx = {
  "/app/matrices/compliance-status": "Compliance Status Report",
  "/app/matrices/delayed-compliance": "Delayed Compliance Report",
  "/app/matrices/event-checklist": "Event Checklist Report",
  "/app/matrices/event-occurance": "Event Occurance Report",
  "/app/matrices/checker-verification": "Pending Checker Verification",
  "/app/matrices/not-applicable": "Unrelated and Not Applicable Compliances",
  "/app/matrices/certificate-generation":
    "Certificate Generation Status Report",
  "/app/matrices/compliance-count": "Owner Wise Compliance Count",
  "/app/matrices/user-login": "User Login Report",
  "/app/matrices/activity-logs": "Audit Logs",
  "/app/compliance-mapping": "Compliance Mapping",
  "/app/new-compliance-mapping": "New Compliance Mapping",
  "/app/add-compliance-master": "Add Compliance Master",
  "/app/edit-compliance-master": "Edit Compliance Master",
  "/app/edit-company-compliance": "Edit Company Compliance",
  "/app/add-company-compliance": "Add Company Compliance",
  "/app/cxo-management": "CXO Management",
  "/app/compliance-detailed-view": "Compliance Detailed View",
  "/app/legal-updates": "Legal Updates",
  "/app/legal-update-setup": "Legal Updates Setup",
  "/app/notices-and-summons-setup": "Notices and Summons Setup",
  "/app/notices-and-summons": "Notices and Summons",
  "/app/notices-and-summons/action-center": "Notices and Summons - Details",
  "/app/division-compliance-view": "Division Compliance View",
  "/app/due-delegation/time": "Due Delegation - Time Based",
  "/app/due-delegation/events": "Due Delegation - Events Based",
  "/app/change-due-date/time": "Change Due Date - Time Based",
  "/app/change-due-date/events": "Change Due Date - Events Based",
  "/app/change-due-date/bulk": "Change Due Date - Bulk",
  "/app/my-compliance-view/time": "My Compliance View - Time Based",
  "/app/my-compliance-view/events": "My Compliance View - Events Based",
  "/app/user-profile/edit-profile": "Edit Profile",
  "/app/user-profile/change-password": "Change Password",
};
function getUrlTitle(url) {
  let result = listOfSideNavEx[url] ? listOfSideNavEx[url] : url;
  return result;
}

function transformListToTree(data) {
  const nodeMap = new Map();
  let rootNodes = [];

  // Initialize the map with nodes
  data.forEach((item) => {
    nodeMap.set(item.id, {
      value: item.uuid,
      title: item.title,
      id: item.id,
      disabled: item.disabled,
      division: item.header || "",
      children: [],
    });
  });

  // Build the tree by assigning children
  data.forEach((item) => {
    if (item.parent) {
      const parentNode = nodeMap.get(item.parent);
      if (parentNode) {
        parentNode.children.push(nodeMap.get(item.id));
      }
    } else {
      rootNodes.push(nodeMap.get(item.id));
    }
  });

  if (rootNodes.length === 1) {
    rootNodes = rootNodes[0].children;
  }

  return rootNodes;
}

function getOrderedUnifiedHierarchy(data, targetUuids) {
  const nodeMap = data.reduce((map, node) => {
    map[node.id] = node;
    return map;
  }, {});

  const includedNodes = new Set();
  const markHierarchyForUuid = (uuid) => {
    const targetNode = data.find((node) => node.uuid === uuid);
    if (!targetNode) return;

    let currentNode = targetNode;
    while (currentNode) {
      includedNodes.add(currentNode.id);
      currentNode = currentNode.parent ? nodeMap[currentNode.parent] : null;
    }
  };
  targetUuids.forEach(markHierarchyForUuid);
  return data.filter((node) => includedNodes.has(node.id));
}

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    companyLoading,
    companyList,
    listProjectsTop,
    projectList,
    listApprovaltask,
    getLoggedinRole,
    listEventTask,
    userRole,
  } = props;

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };
  const [visible, setvisible] = useState(false);
  const [listCompany, setListCompany] = useState({});
  const [listDivision, setlistDivision] = useState(null);
  const [divisionRole, setdivisionRole] = useState(null);
  const [companyRole, setcompanyRole] = useState(null);

  const [listCompanyData, setListCompanyData] = useState([]);
  const [resetValue, setresetValue] = useState(false);

  const [singleCompany, setsingleCompany] = useState(null);
  const [structureData, setstructureData] = useState([]);
  const [hierarchyData, sethierarchyData] = useState([]);

  const companyListSingle = useRef(null);
  companyListSingle.current = () => {
    if (companyList.length > 0 && Object.keys(listCompany).length > 0) {
      let data = companyList.filter(
        (ele) => ele.id === parseInt(listCompany.key)
      );
      if (data.length > 0) {
        setsingleCompany(data[0]);
      } else {
        setsingleCompany(null);
      }
    }
  };

  useEffect(() => {
    companyListSingle.current();
  }, [listCompany]);

  useEffect(() => {
    if (singleCompany !== null && projectList.length > 0) {
      let hierarchyData2 = singleCompany.entity_meta;
      hierarchyData2 = hierarchyData2.map((ele) => {
        return {
          ...ele,
          disabled:
            !projectList.filter((item) => item.div_meta === ele.uuid).length >
            0,
          division:
            projectList.filter((item) => item.div_meta === ele.uuid).length > 0
              ? projectList.filter((item) => item.div_meta === ele.uuid)[0]
                  .division
              : null,
        };
      });
      sethierarchyData(hierarchyData2);
    } else {
      sethierarchyData([]);
    }
  }, [projectList, singleCompany]);

  useEffect(() => {
    if (!companyLoading && companyList.length > 0) {
      var result = [];
      companyList.forEach((e) => {
        if (e.status) result.push({ key: e.id, value: e.id, label: e.name });
      });
      setListCompanyData(result);
      if (localStorage.getItem("company_selected_top") === null) {
        localStorage.setItem("company_selected_top", result[0].value);
        localStorage.setItem("company_selected_name_top", result[0].label);
        localStorage.setItem("company_selected_top_temp", result[0].value);
        setListCompany({
          key: result[0].value,
          label: result[0].label,
          value: result[0].value,
        });
        store.dispatch({ type: TRIGGER_DASHBOARD_API, payload: true });
        listProjectsTop(parseInt(result[0].value));
        if (firstTime) {
          setcompanyRole(result[0].role);
        }
      } else {
        var data = result.filter(
          (item) =>
            parseInt(item.value) ===
            parseInt(localStorage.getItem("company_selected_top"))
        );
        if (data.length > 0) {
          setListCompany({
            key: parseInt(localStorage.getItem("company_selected_top")),
            value: parseInt(localStorage.getItem("company_selected_top")),
            label: data[0].label,
          });
          localStorage.setItem("company_selected_name_top", data[0].label);
          if (firstTime) {
            setcompanyRole(data[0].role);
          }
        } else {
          localStorage.setItem("company_selected_top", result[0].value);
          localStorage.setItem("company_selected_name_top", result[0].label);
          localStorage.setItem("company_selected_top_temp", result[0].value);
          setListCompany({
            key: parseInt(result[0].value),
            value: parseInt(result[0].value),
            label: result[0].label,
          });
          localStorage.setItem("company_selected_name_top", result[0].label);
          if (firstTime) {
            setcompanyRole(result[0].role);
          }
        }
        store.dispatch({ type: TRIGGER_DASHBOARD_API, payload: true });
        listProjectsTop(parseInt(localStorage.getItem("company_selected_top")));
      }
    }
  }, [companyList, companyLoading, listProjectsTop]);

  const [value, setValue] = useState({ value: "", label: "", id: "" });
  useEffect(() => {
    if (projectList.length > 0 && value.value !== "") {
      let valuez = projectList.filter((item) => item.div_meta === value.value);
      if (valuez.length > 0) {
        valuez = valuez[0];
        setlistDivision({
          key: valuez.div_data.project_id,
          value: valuez.div_data.id,
          label: valuez.div_data.name,
        });
        localStorage.setItem("division_selected_top", valuez.division);
        localStorage.setItem(
          "division_selected_name_top",
          valuez.div_data.name
        );
        localStorage.setItem(
          "division_selected_UUID_top",
          valuez.div_data.project_id
        );
        if (firstTime) {
          firstTime = false;
          getLoggedinRole(
            parseInt(localStorage.getItem("company_selected_top_temp")),
            parseInt(localStorage.getItem("division_selected_top"))
          );

          listApprovaltask(
            parseInt(localStorage.getItem("company_selected_top_temp")),
            parseInt(valuez.division),
            0,
            tableRange,
            [],
            ""
          );
          listEventTask(
            parseInt(localStorage.getItem("company_selected_top_temp")),
            parseInt(valuez.division),
            0,
            tableRange,
            [],
            ""
          );
          setdivisionRole(valuez.div_data.user_role);
          store.dispatch({ type: TRIGGER_API, payload: true });
          store.dispatch({ type: TRIGGER_API_2, payload: true });
          store.dispatch({ type: R_TRIGGER_API_1, payload: true });
          store.dispatch({ type: R_TRIGGER_API_2, payload: true });
          store.dispatch({ type: R_TRIGGER_API_3, payload: true });
          store.dispatch({ type: R_TRIGGER_API_4, payload: true });
          store.dispatch({ type: R_TRIGGER_API_5, payload: true });
          store.dispatch({ type: R_TRIGGER_API_6, payload: true });
          store.dispatch({ type: R_TRIGGER_API_7, payload: true });
          store.dispatch({ type: R_TRIGGER_API_8, payload: true });
          store.dispatch({ type: R_TRIGGER_API_9, payload: true });
          store.dispatch({ type: R_TRIGGER_API_10, payload: true });
        }
      }
    }
  }, [
    getLoggedinRole,
    listApprovaltask,
    listEventTask,
    projectList,
    value.value,
  ]);

  useEffect(() => {
    store.dispatch({ type: SET_DIVISION_ROLE, payload: divisionRole });
  }, [divisionRole]);
  useEffect(() => {
    store.dispatch({ type: SET_COMPANY_ROLE, payload: companyRole });
  }, [companyRole]);

  const [showNav, setshowNav] = useState(false);

  const history = useHistory();

  useEffect(() => {
    var flag = listOFlink.indexOf(window.location.pathname) > -1;
    setshowNav(!flag);
    return () => {
      var flag = listOFlink.indexOf(window.location.pathname) > -1;
      setshowNav(!flag);
    };
  }, []);

  history.listen((ele) => {
    var flag = listOFlink.indexOf(ele.pathname) > -1;
    setshowNav(!flag);
  });

  useEffect(() => {
    if (!props.isAuthenticated) {
      firstTime = true;
    }
  }, [props.isAuthenticated]);

  const [treeData, settreeData] = useState([]);

  useEffect(() => {
    if (hierarchyData.length > 0) {
      let newData = getOrderedUnifiedHierarchy(
        hierarchyData,
        hierarchyData.filter((el) => !el.disabled).map((el) => el.uuid)
      );
      if (newData.filter((el) => !el.disabled).length > 0) {
        let firstSelect = newData.filter((el) => !el.disabled)[0];
        if (localStorage.getItem("division_selected_top")) {
          if (
            newData.filter(
              (el) =>
                el.division ===
                  parseInt(localStorage.getItem("division_selected_top")) &&
                !el.disabled
            ).length > 0
          ) {
            firstSelect = newData.filter(
              (el) =>
                el.division ===
                  parseInt(localStorage.getItem("division_selected_top")) &&
                !el.disabled
            )[0];
          }
        }
        setValue({
          value: firstSelect.uuid,
          label: firstSelect.title,
          id: firstSelect.division,
        });
        setresetValue(true);
        setTimeout(() => {
          setresetValue(false);
        }, 10);
      }
      let data = transformListToTree(newData);
      data = [...data];
      settreeData(data);
    } else {
      settreeData([]);
      setValue({ value: "", label: "", id: "" });
      setresetValue(true);
      setTimeout(() => {
        setresetValue(false);
      }, 10);
    }
  }, [hierarchyData]);

  const onChange = (newValue, current) => {
    let newProjid = newValue;
    let data = hierarchyData.filter((ele) => ele.uuid === newProjid);
    if (data.length > 0) {
      newProjid = data[0].division;
    }
    setValue({
      value: newValue,
      label: current[0],
      id: newProjid,
    });
    setresetValue(true);
    setTimeout(() => {
      setresetValue(false);
    }, 10);
  };

  const location = useLocation();

  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  );

  const tProps = {
    treeData,
    value,
    onChange,
    // treeCheckable: true,
    treeDefaultExpandAll: true, // Expand all nodes by default
    placeholder: "Please select",
    className: "mt-2",
    style: {
      width: "100%",
    },
  };

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor, zIndex: "999" }}
    >
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left" style={{ width: "33%" }}>
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
              {userRole !== "COMPLIANCE-ADMIN" && showNav && (
                <li className="ant-menu-item ant-menu-item-only-child">
                  <Tooltip title="Select Team" placement="right">
                    <Link
                      to="#!"
                      onClick={(e) => {
                        if (
                          listCompanyData.length > 1 ||
                          hierarchyData.filter((el) => !el.disabled).length > 1
                        ) {
                          setvisible(true);
                        }
                      }}
                      style={{ fontSize: "13px" }}
                    >
                      {value !== null ? value.label : "No division selected"}
                      <CaretDownOutlined style={{ fontSize: "13px" }} />
                    </Link>
                  </Tooltip>
                </li>
              )}
            </ul>
          </div>
          <div style={{ width: "34%" }} className="text-white text-center">
            <span style={{ fontSize: "20px", fontWeight: "semi-bold" }}>
              {currentRouteInfo?.title &&
              currentRouteInfo?.title !== "sidenav.my-compliance-view-time" &&
              currentRouteInfo?.title !== "sidenav.my-compliance-view-events" &&
              currentRouteInfo?.title !== "sidenav.due-delegation-time" &&
              currentRouteInfo?.title !== "sidenav.due-delegation-events" &&
              currentRouteInfo?.title !== "sidenav.change-due-date-events" &&
              currentRouteInfo?.title !== "sidenav.change-due-date-time" &&
              currentRouteInfo?.title !== "sidenav.change-due-date-bulk" ? (
                <IntlMessage id={currentRouteInfo?.title} />
              ) : (
                getUrlTitle(location.pathname)
              )}
            </span>
          </div>
          <div className="nav-right">
            {/* <NavNotification /> */}
            <NavProfile />
            {/* <NavPanel direction={direction} /> */}
          </div>
          {/* <NavSearch active={searchActive} close={onSearchClose} /> */}
        </div>
      </div>
      <Modal
        title="Select Team"
        visible={visible}
        okText="Save"
        onOk={async (e) => {
          await localStorage.setItem("company_selected_top", listCompany.value);
          await localStorage.setItem(
            "company_selected_name_top",
            listCompany.label
          );
          localStorage.removeItem("division_selected_top");
          localStorage.removeItem("division_selected_name_top");
          localStorage.removeItem("division_selected_UUID_top");
          if (listDivision !== null) {
            projectList.forEach((item) => {
              if (item.division === parseInt(listDivision.value)) {
                setdivisionRole(item.div_data.user_role);
              }
            });
            localStorage.setItem("division_selected_top", listDivision.value);
            localStorage.setItem(
              "division_selected_name_top",
              listDivision.label
            );
            localStorage.setItem(
              "division_selected_UUID_top",
              listDivision.key
            );
            getLoggedinRole(
              parseInt(listCompany.value),
              parseInt(listDivision.value)
            );
            listApprovaltask(
              parseInt(listCompany.value),
              parseInt(listDivision.value),
              0,
              tableRange,
              [],
              ""
            );
            listEventTask(
              parseInt(listCompany.value),
              parseInt(listDivision.value),
              0,
              tableRange,
              [],
              ""
            );
          } else {
            getLoggedinRole(parseInt(listCompany.value), null);
            listApprovaltask(
              parseInt(listCompany.value),
              null,
              0,
              tableRange,
              [],
              ""
            );
            listEventTask(
              parseInt(listCompany.value),
              null,
              0,
              tableRange,
              [],
              ""
            );
            // listTaskData(parseInt(listCompany.value), null);
            setdivisionRole(null);
          }
          if (listCompany !== null) {
            companyList.forEach((item) => {
              if (item.id === parseInt(listCompany.value)) {
                setcompanyRole(item.role);
              }
            });
          } else {
            setcompanyRole(null);
          }
          store.dispatch({ type: TRIGGER_API, payload: true });
          store.dispatch({ type: TRIGGER_API_2, payload: true });
          store.dispatch({ type: R_TRIGGER_API_1, payload: true });
          store.dispatch({ type: R_TRIGGER_API_2, payload: true });
          store.dispatch({ type: R_TRIGGER_API_3, payload: true });
          store.dispatch({ type: R_TRIGGER_API_4, payload: true });
          store.dispatch({ type: R_TRIGGER_API_5, payload: true });
          store.dispatch({ type: R_TRIGGER_API_6, payload: true });
          store.dispatch({ type: R_TRIGGER_API_7, payload: true });
          store.dispatch({ type: R_TRIGGER_API_8, payload: true });
          store.dispatch({ type: R_TRIGGER_API_9, payload: true });
          store.dispatch({ type: R_TRIGGER_API_10, payload: true });
          setvisible(false);
          window.location.href = "/app/matrices/dashboard";
        }}
        onCancel={(e) => {
          setListCompany({
            key: parseInt(localStorage.getItem("company_selected_top")),
            value: parseInt(localStorage.getItem("company_selected_top")),
            label: localStorage.getItem("company_selected_name_top"),
          });
          localStorage.setItem(
            "company_selected_top_temp",
            localStorage.getItem("company_selected_top")
          );
          store.dispatch({ type: TRIGGER_DASHBOARD_API, payload: true });
          listProjectsTop(
            parseInt(localStorage.getItem("company_selected_top"))
          );
          setvisible(false);
        }}
      >
        <Row gutter={16}>
          <Col span={24} className="pr-2 mb-2">
            <label
              className="mb-0 font-weight-semibold d-block text-black "
              style={{ color: "rgb(26, 51, 83)" }}
            >
              Company
            </label>
            <Select
              showSearch
              labelInValue
              style={{ width: "100%" }}
              className="mt-1"
              placeholder="Select Company"
              optionFilterProp="children"
              value={listCompany.hasOwnProperty("value") ? listCompany : null}
              onChange={async (value, key) => {
                setListCompany(value);
                localStorage.setItem("company_selected_top_temp", value.value);
                setlistDivision(null);
                store.dispatch({ type: TRIGGER_DASHBOARD_API, payload: true });
                listProjectsTop(value.key);
              }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {listCompanyData.map((item, index) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col sm={24} md={24} lg={24} className="pl-2">
            <label
              className="mb-0 font-weight-semibold d-block text-black "
              style={{ color: "rgb(26, 51, 83)" }}
            >
              Select Team
            </label>
            {!resetValue && <TreeSelect {...tProps} />}
          </Col>
          {/* <Col span={12} className="pl-2">
            <Select
              showSearch
              labelInValue
              className="ml-2"
              style={{ width: "100%" }}
              placeholder="Select Division"
              optionFilterProp="children"
              value={listDivision}
              onChange={(value, key) => {
                setlistDivision(value);
                localStorage.setItem("division_selected_top", value.value);
                localStorage.setItem(
                  "division_selected_name_top",
                  value.label
                );
                localStorage.setItem("division_selected_UUID_top", value.key);
              }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {listDivisionData.map((item, index) => (
                <Option key={item.key} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Col> */}
          {structureData.map(
            (item, index) =>
              item.chidren.length > 0 && (
                <Col span={12} className="pl-2 mb-2" key={index}>
                  <label
                    className="mb-0 font-weight-semibold d-block text-black"
                    style={{ color: "rgb(26, 51, 83)" }}
                  >
                    {index === 0 ? "Subsidiary" : item.header}{" "}
                  </label>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select"
                    className="mt-1"
                    optionFilterProp="children"
                    value={item.selected}
                    onChange={(e) => {
                      let data = [...structureData];
                      data = data.slice(0, index + 1);
                      let hierarchyData2 = hierarchyData;
                      if (e !== "") {
                        let single = hierarchyData2.filter(
                          (ele) => ele.id === e
                        );
                        data[index].division = single[0]?.division;
                        if (single.length > 0) {
                          let children = hierarchyData2
                            .filter((item) => item.parent === single[0].id)
                            .map((ele, index) => {
                              return {
                                value: ele.id,
                                label: ele.title,
                                key: ele.id,
                              };
                            });
                          if (children.length > 0) {
                            data.push({
                              ...single[0],
                              chidren: children,
                              isOpen: false,
                              selected: "",
                            });
                          }
                        }
                      } else {
                        if (index > 0) {
                          data[index].division = data[index - 1].division;
                        }
                      }
                      data[index].selected = e;
                      setstructureData(data);
                    }}
                    onFocus={() => {}}
                    onBlur={() => {}}
                    onSearch={() => {}}
                  >
                    <Select.Option value={""} disabled={index === 0}>
                      Select
                    </Select.Option>
                    {item.chidren.map((ele, index) => (
                      <Select.Option key={ele.key} value={ele.value}>
                        {ele.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              )
          )}
        </Row>
      </Modal>
    </Header>
  );
};

const mapStateToProps = ({ theme, company, project, auth }) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
    isAuthenticated,
  } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
    isAuthenticated,
    companyLoading: company.loading,
    companyList: company.data,
    projectLoading: project.loading,
    projectList: project.topProjectList,
    userRole: auth.customeUserRole,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  listProjectsTop,
  onMobileNavToggle,
  listComplianceForUser,
  listApprovaltask,
  listEventTask,
  listTaskData,
  getLoggedinRole,
})(HeaderNav);
