import React from "react";
import { Menu, Dropdown, Avatar, Tooltip } from "antd";
import { connect } from "react-redux";
import { EditOutlined, LogoutOutlined } from "@ant-design/icons";
import Icon from "../../components/util-components/Icon";
import PropTypes from "prop-types";
import { logout } from "../../apis/login/auth";
import { Link } from "react-router-dom";

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "/app/user-profile",
  },

  // {
  //   title: 'Account Setting',
  //   icon: SettingOutlined,
  //   path: '/',
  // },
  // {
  //   title: 'Help Center',
  //   icon: QuestionCircleOutlined,
  //   path: '/',
  // },
];

export const NavProfile = ({ logout, data, customeUserRole, profileData }) => {
  const profileImg = "/img/avatars/thumb-1.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">
              {profileData.first_name + " " + profileData.last_name}
            </h4>
            <span className="text-muted">
              {data.role === "USER"
                ? "USER"
                : data.role === "CUSTOM-USER"
                ? customeUserRole
                : data.role === "PROJECT-ADMIN"
                ? "LEGAL-ADMIN"
                : data.role}
            </span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <Link to={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={(e) => logout()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  const name = profileData.first_name + " " + profileData.last_name;

  return (
    <>
      <Tooltip title={name} placement="left">
        <span className="text-white ">
          {name.length > 18 ? name.slice(0, 18) + "..." : name}
        </span>
      </Tooltip>
      <Dropdown
        placement="bottomRight"
        overlay={profileMenu}
        trigger={["click"]}
      >
        <Menu className="d-flex align-item-center" mode="horizontal">
          <Menu.Item key="profile">
            <Avatar src={profileImg} style={{ marginTop: "-10px" }} />
          </Menu.Item>
        </Menu>
      </Dropdown>
    </>
  );
};

NavProfile.propTypes = {
  isAuthenticated: PropTypes.object,
  customeUserRole: PropTypes.any,
  profileData: PropTypes.any,
};
const mapStateToProps = (state) => ({
  data: state.auth.data,
  customeUserRole: state.auth.customeUserRole,
  profileData: state.auth.UserByEmail,
});

export default connect(mapStateToProps, { logout })(NavProfile);
