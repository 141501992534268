import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "../../components/shared-components/Loading";
import { APP_PREFIX_PATH } from "../../configs/AppConfig";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import store from "../../redux/store";
import { LOGOUT } from "../../actions/types";
import { message, Modal } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const AppViews = ({
  isAuthenticated,
  authLoading,
  setLogoutProvision,
  globalAlert,
  globalAlertType,
  globalAlertMessage,
}) => {
  const history = useHistory();

  useEffect(() => {
    try {
      if (localStorage.getItem("paramsListData")) {
        let params = JSON.parse(localStorage.getItem("paramsListData"));
        if (Object.keys(params).length > 0) {
          if (params.task !== "approve") {
            if (params.types.includes("Time")) {
              history.push("/app/my-compliance-view/time");
            } else {
              history.push("/app/my-compliance-view/events");
            }
          } else {
            history.push("/auth/approve-instance");
          }
        }
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong please try again");
      localStorage.removeItem("paramsListData");
    }
  }, [history]);

  useEffect(() => {
    if (setLogoutProvision) {
      let params = JSON.parse(localStorage.getItem("paramsListData"));
      localStorage.clear();
      sessionStorage.clear();
      if (params)
        localStorage.setItem("paramsListData", JSON.stringify(params));
      var html = (
        <div className="text-left">
          This sesssion is invalid. Please insure following best practices to
          avoid this. <br />
          1. Do not share your credentials with anyone. <br />
          2. Multiple users cannot use same credentials at the same time. <br />
          3. Application can be used in one browser tab at a time.
        </div>
      );
      Modal.warning({
        title: "You have been logged out.",
        content: html,
        onOk() {
          store.dispatch({ type: LOGOUT });
          localStorage.clear();
          localStorage.clear();
          if (params)
            localStorage.setItem("paramsListData", JSON.stringify(params));
          window.location.href = "/auth";
        },
      });
    }
  }, [setLogoutProvision]);

  useEffect(() => {
    if (globalAlert && globalAlertType === "PASS") {
      message.success(globalAlertMessage);
    }
    if (globalAlert && globalAlertType === "FAIL") {
      message.error(globalAlertMessage);
    }
  }, [globalAlert, globalAlertType, globalAlertMessage]);

  if (!isAuthenticated && !authLoading) {
    let params = JSON.parse(localStorage.getItem("paramsListData"));
    if (params) localStorage.setItem("paramsListData", JSON.stringify(params));
    window.location.href = "/auth";
    // return <Redirect to='/auth' />;
  }

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/company-management`}
          component={lazy(() => import(`./company`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/division-management`}
          component={lazy(() => import(`./project`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/access-management`}
          component={lazy(() => import(`./access`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/user-management`}
          component={lazy(() => import(`./user`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/compliance-master`}
          component={lazy(() => import(`./complianceMaster`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/add-compliance-master`}
          component={lazy(() => import(`./complianceMaster/AddCompliance`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/compliance-detailed-view`}
          component={lazy(() => import(`./complianceMaster/ViewDetails`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/legal-updates`}
          component={lazy(() => import(`./LegalUpdates/CardView`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/legal-update-setup`}
          component={lazy(() => import(`./LegalUpdates`))}
        />
        {/* <Route
          path={`${APP_PREFIX_PATH}/notices-and-summons-setup`}
          component={lazy(() => import(`./NoticeSummons`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/notices-and-summons`}
          component={lazy(() => import(`./NoticeSummons/ActionCenter`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/notices-and-summons/action-center`}
          component={lazy(() =>
            import(`./NoticeSummons/ActionCenter/ActionCenter.js`)
          )}
        /> */}
        <Route
          path={`${APP_PREFIX_PATH}/compliance-division-detailed-view`}
          component={lazy(() => import(`./myCompliance/myComplianceView`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/edit-compliance-master`}
          component={lazy(() => import(`./complianceMaster/EditCompliance`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/compliance-mapping`}
          component={lazy(() => import(`./complianceMapping`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/new-compliance-mapping`}
          component={lazy(() => import(`./complianceMapping/MapNew`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/compliance-admin`}
          component={lazy(() => import(`./complianceAdmin`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cxo-management`}
          component={lazy(() => import(`./cxoAdmin`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/company-compliance`}
          component={lazy(() => import(`./companyCompliance`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/legal-admin`}
          component={lazy(() => import(`./legalAdmin`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/add-company-compliance`}
          component={lazy(() => import(`./companyCompliance/AddCompliance`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/edit-company-compliance`}
          component={lazy(() => import(`./companyCompliance/EditCompliance`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/division-compliance`}
          component={lazy(() => import(`./divisionCompliance`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/division-compliance-view`}
          component={lazy(() => import(`./divisionCompliance/ViewCompliance`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/compliance-calender-view`}
          component={lazy(() => import(`./ComplianceView`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/my-compliance-view/time`}
          component={lazy(() => import(`./myCompliance`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/my-compliance-view/events`}
          component={lazy(() => import(`./myCompliance`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/due-delegation/time`}
          component={lazy(() => import(`./Delegation`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/due-delegation/events`}
          component={lazy(() => import(`./Delegation`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/change-due-date/time`}
          component={lazy(() => import(`./DueDateChange`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/change-due-date/events`}
          component={lazy(() => import(`./DueDateChange`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/change-due-date/bulk`}
          component={lazy(() => import(`./DueDateChange/BulkUpload`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/compliance-approval`}
          component={lazy(() => import(`./Approvals`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/auditing-action`}
          component={lazy(() => import(`./Auditing`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/auditor-management`}
          component={lazy(() => import(`./AuditorManagement`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/events`}
          component={lazy(() => import(`./Events`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/matrices/certificate`}
          component={lazy(() => import(`./Matrices/Certificates`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/matrices/activity-logs`}
          component={lazy(() => import(`./Matrices/Reports/ActivityLogs`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/matrices/reports`}
          component={lazy(() => import(`./Matrices/Reports`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/matrices/compliance-status`}
          component={lazy(() => import(`./Matrices/Reports/ComplianceStatus`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/matrices/action-plan`}
          component={lazy(() => import(`./Matrices/Reports/ActionPlan`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/matrices/certificate-generation`}
          component={lazy(() =>
            import(`./Matrices/Reports/CertificateGeneration`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/matrices/checker-verification`}
          component={lazy(() =>
            import(`./Matrices/Reports/CheckerVerification`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/matrices/compliance-count`}
          component={lazy(() => import(`./Matrices/Reports/ComplianceCount`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/matrices/event-occurance`}
          component={lazy(() => import(`./Matrices/Reports/EventOccurance`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/matrices/not-applicable`}
          component={lazy(() => import(`./Matrices/Reports/NotApplicable`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/matrices/user-login`}
          component={lazy(() => import(`./Matrices/Reports/UserLogin`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/matrices/delayed-compliance`}
          component={lazy(() => import(`./Matrices/Reports/DelayedCompliance`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/matrices/event-checklist`}
          component={lazy(() => import(`./Matrices/Reports/EventChecklist`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/matrices/dashboard`}
          component={lazy(() => import(`./Matrices/Dashboard2`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/user-profile`}
          component={lazy(() => import(`./ProfileManage`))}
        />
      </Switch>
    </Suspense>
  );
};
AppViews.propTypes = {
  authLoading: PropTypes.bool,
  setLogoutProvision: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  authLoading: state.auth.loading,
  setLogoutProvision: state.auth.setLogoutProvision,
  globalAlert: state.compliance.globalAlert,
  globalAlertType: state.compliance.globalAlertType,
  globalAlertMessage: state.compliance.globalAlertMessage,
});
export default connect(mapStateToProps, {})(AppViews);
