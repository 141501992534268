//Dev URL
export const BACKEND_URL = "https://digi-ops.momenttext.com/v13/";
export const BACKEND_URL_2 = "https://digi-ops.momenttext.com/v14/";

// new Dev URL
// export const BACKEND_URL = "https://kcri.ikffinapp.com/v13/";
// export const BACKEND_URL_2 = "https://kcri.ikffinapp.com/v14/";

// export const BACKEND_URL = 'https://65.1.91.166/v13/';
// export const BACKEND_URL_2 = 'https://65.1.91.166/v14/';

//Stag URL
// export const BACKEND_URL = "http://kcra.momenttext.com/v15/";
// export const BACKEND_URL_2 = "http://kcra.momenttext.com/v16/";

//Production URLs

//kcra_axis_prod
// export const BACKEND_URL = 'https://aflkpmgprod.axisb.com/v13/';
// export const BACKEND_URL_2 = 'https://aflkpmgprod.axisb.com/v14/';

//bellsonica
// export const BACKEND_URL = 'http://192.168.128.224/v13/';
// export const BACKEND_URL_2 = 'http://192.168.128.224/v14/';

//old bellsonica
// export const BACKEND_URL = 'http://192.168.130.153/v13/';
// export const BACKEND_URL_2 = 'http://192.168.130.153/v14/';

//kcra_axis
// export const BACKEND_URL = 'https://10.0.124.42/v13/';
// export const BACKEND_URL_2 = 'https://10.0.124.42/v14/';

//kpmg_admin
// export const BACKEND_URL = 'http://10.188.7.76/v13/';
// export const BACKEND_URL_2 = 'http://10.188.7.76/v14/';

//suzlon
// export const BACKEND_URL = 'https://legalcompliance.suzlon.com/v13/';
// export const BACKEND_URL_2 = 'https://legalcompliance.suzlon.com/v14/';

//other settings
export const EVENT_MAKER_CHECKER = "1";
export const EVENT_REQUIRED = "1";
export const EVENT_TRIGGER_FUTURE_DATE = "0";

//auth
export const ADFS_URL = "https://adfsuat.axisb.com/adfs/ls/";
export const SSO_LOGIN = "1";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const OTP_FREEZE = "OTP_FREEZE";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const TOKEN_CHECK = "TOKEN_CHECK";
export const TOKEN_CHECK_FAIL = "TOKEN_CHECK_FAIL";
export const LOGIN_ALERT_REMOVE = "LOGIN_ALERT_REMOVE";
export const LOGOUT = "LOGOUT";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAILED = "RESET_FAILED";
export const GET_USER_DATA_BY_EMAIL = "GET_USER_DATA_BY_EMAIL";
export const SET_LOADING_FALSE = "SET_LOADING_FALSE";
export const SET_LOGOUT_PROVISION = "SET_LOGOUT_PROVISION";
export const SET_LOADING_TRUE = "SET_LOADING_TRUE";
export const RESET_LOGIN_FAILED = "RESET_LOGIN_FAILED";
export const OTP_SETTING = "OTP_SETTING";
export const OTP_FALSE = "OTP_FALSE";
export const SET_DIVISION_ROLE = "SET_DIVISION_ROLE";
export const SET_COMPANY_ROLE = "SET_COMPANY_ROLE";
export const IDLE_TIMEOUT = "IDLE_TIMEOUT";
export const SET_OTP_TIMEOUT = "SET_OTP_TIMEOUT";
export const SET_CHECK_TOKEN_BOOL = "SET_CHECK_TOKEN_BOOL";
export const RESET_IDLE_TIME = "RESET_IDLE_TIME";
export const LIST_OF_ACTIVE_PROCESS = "LIST_OF_ACTIVE_PROCESS";

//company
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAILED = "CREATE_COMPANY_FAILED";
export const REMOVE_COMPANY_ALERT = "REMOVE_COMPANY_ALERT";
export const CLOSE_ADD_MODAL = "CLOSE_ADD_MODAL";
export const CLOSE_EDIT_MODAL = "CLOSE_EDIT_MODAL";
export const CLOSE_ADD_MODAL_RESET = "CLOSE_ADD_MODAL_RESET";
export const CLOSE_EDIT_MODAL_RESET = "CLOSE_EDIT_MODAL_RESET";
export const LIST_COMPANY_SUCESS = "LIST_COMPANY_SUCESS";
export const LIST_COMPANY_FAILED = "LIST_COMPANY_FAILED";
export const EDIT_COMPANY_DATA = "EDIT_COMPANY_DATA";
export const DEACTIVATE_COMPANY_SUCCESS = "DEACTIVATE_COMPANY_SUCCESS";
export const DEACTIVATE_COMPANY_FAILED = "DEACTIVATE_COMPANY_FAILED";
export const EDIT_COMPNAY_FAILED = "EDIT_COMPNAY_FAILED";
export const EDIT_COMPNAY_SUCCESS = "EDIT_COMPNAY_SUCCESS";
export const DEACTIVE_USER_ROLE_FAILED = "DEACTIVE_USER_ROLE_FAILED";
export const SETTINGS_DATA_SUCCESS = "SETTINGS_DATA_SUCCESS";
export const TRIGGER_DASHBOARD_API = "TRIGGER_DASHBOARD_API";

//user
export const CHECK_USER_EMAIL_VALID = "CHECK_USER_EMAIL_VALID";
export const CHECK_USER_EMAIL_INVALID = "CHECK_USER_EMAIL_INVALID";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const COMPANY_ALERT_REMOVE = "COMPANY_ALERT_REMOVE";
export const GET_ROLE_ERROR = "GET_ROLE_ERROR";
export const USER_ASSIGNED_SUCCESS = "USER_ASSIGNED_SUCCESS";
export const ADD_OLD_USER_ID = "ADD_OLD_USER_ID";
export const RESET_ERROR_USER = "RESET_ERROR_USER";

//user Management
export const LIST_USER_LIST_DATA_SUCCESS = "LIST_USER_LIST_DATA_SUCCESS";
export const LIST_USER_LIST_DATA_FAILED = "LIST_USER_LIST_DATA_FAILED";
export const ADD_USER_LIST_DATA_SUCCESS = "ADD_USER_LIST_DATA_SUCCESS";
export const ADD_USER_LIST_DATA_FAILED = "ADD_USER_LIST_DATA_FAILED";
export const EDIT_USER_LIST_DATA_SUCCESS = "EDIT_USER_LIST_DATA_SUCCESS";
export const EDIT_USER_LIST_DATA_FAILED = "EDIT_USER_LIST_DATA_FAILED";
export const SET_ISOPEN_USER_LIST_DATA = "SET_ISOPEN_USER_LIST_DATA";
export const REMOVE_ISOPEN_USER_LIST_DATA = "REMOVE_ISOPEN_USER_LIST_DATA";
export const REMOVE_USER_LIST_DATA_ALERT = "REMOVE_USER_LIST_DATA_ALERT";
export const LIST_USER_BY_EMAIL_DATA_SUCESS = "LIST_USER_BY_EMAIL_DATA_SUCESS";
export const LIST_USER_BY_EMAIL_DATA_FAILED = "LIST_USER_BY_EMAIL_DATA_FAILED";
export const LIST_USER_COMPANY_LIST_SUCCESS = "LIST_USER_COMPANY_LIST_SUCCESS";
export const LIST_USER_COMPANY_LIST_FAILED = "LIST_USER_COMPANY_LIST_FAILED";
export const LIST_USER_PROJECT_LIST_SUCCESS = "LIST_USER_PROJECT_LIST_SUCCESS";
export const LIST_USER_PROJECT_LIST_FAILED = "LIST_USER_PROJECT_LIST_FAILED";

//project
export const LIST_PROJECT_SUCCESS = "LIST_PROJECT_SUCCESS";
export const LIST_PROJECT_SUCCESS_SCP = "LIST_PROJECT_SUCCESS_SCP";
export const LIST_PROJECT_SUCCESS_TOP = "LIST_PROJECT_SUCCESS_TOP";
export const LIST_PROJECT_FAILED_TOP = "LIST_PROJECT_FAILED_TOP";
export const LIST_PROJECT_FAILED = "LIST_PROJECT_FAILED";
export const REMOVE_PROJECT_ALERT = "REMOVE_PROJECT_ALERT";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_FAILED = "ADD_PROJECT_FAILED";
export const SET_ISOPEN_PROJECT = "SET_ISOPEN_PROJECT";
export const REMOVE_ISOPEN_PROJECT = "REMOVE_ISOPEN_PROJECT";
export const UNDER_PROCESSING_PROJECT = "UNDER_PROCESSING_PROJECT";
export const LOADING_TRUE_PROJECT = "LOADING_TRUE_PROJECT";
export const LOADING_FALSE_PROJECT = "LOADING_FALSE_PROJECT";
export const DEACTIVATE_PROJECT_SUCCESS = "DEACTIVATE_PROJECT_SUCCESS";
export const DEACTIVATE_PROJECT_FAILED = "DEACTIVATE_PROJECT_FAILED";

export const EDIT_PROJECT_DATA = "EDIT_PROJECT_DATA";
export const EDIT_PROJECT_FAILED = "EDIT_PROJECT_FAILED";
export const EDIT_PROJECT_SUCCESS = "EDIT_PROJECT_SUCCESS";

//Compliance Admin Crud
export const LIST_COMPLIANCE_ADMIN_SUCCESS = "LIST_COMPLIANCE_ADMIN_SUCCESS";
export const LIST_COMPLIANCE_ADMIN_FAILED = "LIST_COMPLIANCE_ADMIN_FAILED";
export const REMOVE_COMPLIANCE_ADMIN_ALERT = "REMOVE_COMPLIANCE_ADMIN_ALERT";
export const DEACTIVATE_COMPLIANCE_ADMIN_SUCCESS =
  "DEACTIVATE_COMPLIANCE_ADMIN_SUCCESS";
export const DEACTIVATE_COMPLIANCE_ADMIN_FAILED =
  "DEACTIVATE_COMPLIANCE_ADMIN_FAILED";
export const CREATE_COMPLIANCE_ADMIN_FAILED = "CREATE_COMPLIANCE_ADMIN_FAILED";
export const CREATE_COMPLIANCE_ADMIN_SUCCESS =
  "CREATE_COMPLIANCE_ADMIN_SUCCESS";
export const SET_ISOPEN_COMPLIANCE = "SET_ISOPEN_COMPLIANCE";

//compliance master crud
export const LIST_COMPLIANCE_MASTER_SUCCESS = "LIST_COMPLIANCE_MASTER_SUCCESS";
export const LIST_COMPLIANCE_MASTER_FAILED = "LIST_COMPLIANCE_MASTER_FAILED";
export const COMPLIANCE_MASTER_SUCCESS = "COMPLIANCE_MASTER_SUCCESS";
export const COMPLIANCE_MASTER_FAILED = "COMPLIANCE_MASTER_FAILED";
export const SET_EDIT_COMPLIANCE_DATA = "SET_EDIT_COMPLIANCE_DATA";
export const SET_EDIT_COMPLIANCE_DATA_API = "SET_EDIT_COMPLIANCE_DATA_API";
export const SET_BULK_LOADER = "SET_BULK_LOADER";
export const SET_BUILK_MESSAGE = "SET_BUILK_MESSAGE";
export const SET_RANGE_FOR_LIST = "SET_RANGE_FOR_LIST";

//compliance operation
export const LIST_COMPANY_COMPLIANCE_SUCCESS =
  "LIST_COMPANY_COMPLIANCE_SUCCESS";
export const LIST_COMPANY_COMPLIANCE_FAILED = "LIST_COMPANY_COMPLIANCE_FAILED";
export const SET_COMPNAY_COMPLIANCE_ID = "SET_COMPNAY_COMPLIANCE_ID";
export const LIST_COMPANY_COMPLIANCE_AVAILABLE_SUCCESS =
  "LIST_COMPANY_COMPLIANCE_AVAILABLE_SUCCESS";
export const LIST_COMPANY_COMPLIANCE_AVAILABLE_FAILED =
  "LIST_COMPANY_COMPLIANCE_AVAILABLE_FAILED";
export const COMPLIANCE_ALERT_SET = "COMPLIANCE_ALERT_SET";
export const COMPLIANCE_ALERT_REMOVE = "COMPLIANCE_ALERT_REMOVE";
export const LIST_DIVISION_COMPLIANCE_SUCCESS =
  "LIST_DIVISION_COMPLIANCE_SUCCESS";
export const LIST_DIVISION_COMPLIANCE_FAILED =
  "LIST_DIVISION_COMPLIANCE_FAILED";
export const RESET_DIVISION_DATA = "RESET_DIVISION_DATA";
export const CREATE_DIVISION_USER_FAILED = "CREATE_DIVISION_USER_FAILED";
export const CREATE_DIVISION_USER_SUCCESS = "CREATE_DIVISION_USER_SUCCESS";
export const SET_ISOPEN_DIVISION_USER = "SET_ISOPEN_DIVISION_USER";

//CXO Admin Crud
export const LIST_CXO_ADMIN_SUCCESS = "LIST_CXO_ADMIN_SUCCESS";
export const LIST_CXO_ADMIN_FAILED = "LIST_CXO_ADMIN_FAILED";
export const REMOVE_CXO_ADMIN_ALERT = "REMOVE_CXO_ADMIN_ALERT";
export const DEACTIVATE_CXO_ADMIN_SUCCESS = "DEACTIVATE_CXO_ADMIN_SUCCESS";
export const DEACTIVATE_CXO_ADMIN_FAILED = "DEACTIVATE_CXO_ADMIN_FAILED";
export const CREATE_CXO_ADMIN_FAILED = "CREATE_CXO_ADMIN_FAILED";
export const CREATE_CXO_ADMIN_SUCCESS = "CREATE_CXO_ADMIN_SUCCESS";
export const SET_ISOPEN_CXO = "SET_ISOPEN_CXO";

//user sepecific Compliance Details
export const LIST_MY_COMPLIANCE_DETAIL_SUCCESS =
  "LIST_MY_COMPLIANCE_DETAIL_SUCCESS";
export const LIST_MY_COMPLIANCE_DETAIL_FAILED =
  "LIST_MY_COMPLIANCE_DETAIL_FAILED";
export const SET_ALERT_FOR_MY_COMPLIANCE = "SET_ALERT_FOR_MY_COMPLIANCE";
export const RESET_ALERT_FOR_MY_COMPLIANCE = "RESET_ALERT_FOR_MY_COMPLIANCE";
export const LIST_TIME_BASED_INSTANCE_SUCCESS =
  "LIST_TIME_BASED_INSTANCE_SUCCESS";
export const LIST_TIME_BASED_INSTANCE_FAILED =
  "LIST_TIME_BASED_INSTANCE_FAILED";
export const LIST_EVENT_BASED_INSTANCE_SUCCESS =
  "LIST_EVENT_BASED_INSTANCE_SUCCESS";
export const LIST_EVENT_BASED_INSTANCE_FAILED =
  "LIST_EVENT_BASED_INSTANCE_FAILED";
export const LIST_DETAILED_INSTANCE_SUCCESS = "LIST_DETAILED_INSTANCE_SUCCESS";
export const LIST_DETAILED_INSTANCE_FAILED = "LIST_DETAILED_INSTANCE_FAILED";
export const LIST_SAVED_DETAILED_INSTANCE_SUCCESS =
  "LIST_SAVED_DETAILED_INSTANCE_SUCCESS";
export const LIST_SAVED_DETAILED_INSTANCE_FAILED =
  "LIST_SAVED_DETAILED_INSTANCE_FAILED";

export const SET_USER_ROLE = "SET_USER_ROLE";

export const GLOBAL_ALERT_SYSTEM = "GLOBAL_ALERT_SYSTEM";
export const GLOBAL_ALERT_SYSTEM_2 = "GLOBAL_ALERT_SYSTEM_2";
export const CUSTOM_USER = "CUSTOM_USER";
export const CLEAR_INSTANCE_DATA = "CLEAR_INSTANCE_DATA";
export const CLEAR_INSTANCE_DATA_SAVE = "CLEAR_INSTANCE_DATA_SAVE";
export const LIST_INSTANCE_FOR_APPROVAL = "LIST_INSTANCE_FOR_APPROVAL";
export const LIST_INSTANCE_FOR_EVENTS = "LIST_INSTANCE_FOR_EVENTS";
export const LIST_TASK_INSTANCE_DATA = "LIST_TASK_INSTANCE_DATA";
export const LIST_DASHBOARD_DATA = "LIST_DASHBOARD_DATA";
export const LIST_DASHBOARD_DATA_LOADING = "LIST_DASHBOARD_DATA_LOADING";
export const LIST_CERTIFCATE_DATA = "LIST_CERTIFCATE_DATA";
export const LIST_CERTIFCATE_DATA_LOADING = "LIST_CERTIFCATE_DATA_LOADING";
export const GET_REASONS = "GET_REASONS";

//loading
export const TRIGGER_API = "TRIGGER_API";
export const TRIGGER_API_2 = "TRIGGER_API_2";
export const LIST_COMPLIANCE_MASTER_LOADING = "LIST_COMPLIANCE_MASTER_LOADING";
export const LIST_MY_COMPLIANCE_1_DATA = "LIST_MY_COMPLIANCE_1_DATA";
export const LIST_MY_COMPLIANCE_2_DATA = "LIST_MY_COMPLIANCE_2_DATA";
export const LIST_APPROVAL_LOADING = "LIST_APPROVAL_LOADING";
export const LIST_TASKS_LOADING = "LIST_TASKS_LOADING";
export const LIST_EVENTS_LOADING = "LIST_EVENTS_LOADING";
export const LIST_COMPANY_COMPLIANCE_LOADING =
  "LIST_COMPANY_COMPLIANCE_LOADING";
export const LIST_COMPANY_AVAILABLE_COMPLIANCE_LOADING =
  "LIST_COMPANY_AVAILABLE_COMPLIANCE_LOADING";
export const LIST_DIVISION_COMPLIANCE_LOADING =
  "LIST_DIVISION_COMPLIANCE_LOADING";
export const UPLOADING_FILES_MY_COMPLIANCE = "UPLOADING_FILES_MY_COMPLIANCE";
export const INSTANCE_LOADING_DATA = "INSTANCE_LOADING_DATA";

//reports
export const REPORT_COMPLIANCE_STATUS = "LIST_COMPLIANCE_STATUS";
export const REPORT_COMPLIANCE_STATUS_LOADING =
  "LIST_COMPLIANCE_STATUS_LOADING";
export const R_TRIGGER_API_1 = "R_TRIGGER_API_1";

export const REPORT_DELAYED_COMPLIANCE = "LIST_DELAYED_COMPLIANCE";
export const REPORT_DELAYED_COMPLIANCE_LOADING =
  "REPORT_DELAYED_COMPLIANCE_LOADING";
export const R_TRIGGER_API_2 = "R_TRIGGER_API_2";

export const REPORT_EVENT_CHECKLIST = "LIST_EVENT_CHECKLIST";
export const REPORT_EVENT_CHECKLIST_LOADING = "REPORT_EVENT_CHECKLIST_LOADING";
export const R_TRIGGER_API_3 = "R_TRIGGER_API_3";

export const REPORT_EVENT_OCCURANCE = "LIST_EVENT_OCCURANCE";
export const REPORT_EVENT_OCCURANCE_LOADING = "REPORT_EVENT_OCCURANCE_LOADING";
export const R_TRIGGER_API_4 = "R_TRIGGER_API_4";

export const REPORT_CHECKER_VERIFICATION = "LIST_CHECKER_VERIFICATION";
export const REPORT_CHECKER_VERIFICATION_LOADING =
  "REPORT_CHECKER_VERIFICATION_LOADING";
export const R_TRIGGER_API_5 = "R_TRIGGER_API_5";
export const COMPLIANCE_DATA_DASHBOARD = "COMPLIANCE_DATA_DASHBOARD";

export const REPORT_ACTION_PLAN = "LIST_ACTION_PLAN";
export const REPORT_ACTION_PLAN_LOADING = "REPORT_ACTION_PLAN_LOADING";
export const R_TRIGGER_API_6 = "R_TRIGGER_API_6";

export const REPORT_CERTIFICATE_GENERATION = "LIST_CERTIFICATE_GENERATION";
export const REPORT_CERTIFICATE_GENERATION_LOADING =
  "REPORT_CERTIFICATE_GENERATION_LOADING";
export const R_TRIGGER_API_7 = "R_TRIGGER_API_7";

export const REPORT_COMPLIANCE_COUNT = "LIST_COMPLIANCE_COUNT";
export const REPORT_COMPLIANCE_COUNT_LOADING =
  "REPORT_COMPLIANCE_COUNT_LOADING";
export const R_TRIGGER_API_8 = "R_TRIGGER_API_8";

export const REPORT_USER_LOGIN = "LIST_USER_LOGIN";
export const REPORT_USER_LOGIN_LOADING = "REPORT_USER_LOGIN_LOADING";
export const R_TRIGGER_API_9 = "R_TRIGGER_API_9";

export const REPORT_NOT_APPLICABLE = "LIST_NOT_APPLICABLE";
export const REPORT_NOT_APPLICABLE_LOADING = "REPORT_NOT_APPLICABLE_LOADING";
export const R_TRIGGER_API_10 = "R_TRIGGER_API_10";

export const SHOW_APPROVAL_TAB = "SHOW_APPROVAL_TAB";

export const SET_DOWNLOAD_CERTIFICATE_PARAMETER =
  "SET_DOWNLOAD_CERTIFICATE_PARAMETER";

export const SET_DOWNLOAD_ACTIVITY_LOG = "SET_DOWNLOAD_ACTIVITY_LOG";

export const USER_DOWNLOAD_CERTIFICATE_PARAMETER =
  "USER_DOWNLOAD_CERTIFICATE_PARAMETER";

export const MASTER_DOWNLOAD_CERTIFICATE_PARAMETER =
  "MASTER_DOWNLOAD_CERTIFICATE_PARAMETER";

export const COMPLIANCE_MAPPING_PARAMETER = "COMPLIANCE_MAPPING_PARAMETER";
export const COMPANAY_COMPLIANCE_MAPPING_PARAMETER =
  "COMPANAY_COMPLIANCE_MAPPING_PARAMETER";

//------------------filters changes-------------------------------------------------------------
export const MASTER_COMPLIANCE_RELOAD = "MASTER_COMPLIANCE_RELOAD";
export const MY_COMPLIANCE_TOTAL_RECORDS = "MY_COMPLIANCE_TOTAL_RECORDS";
export const MY_COMPLIANCE_RELOAD = "MY_COMPLIANCE_RELOAD";
export const APPROVAL_TOTAL_RECORDS = "APPROVAL_TOTAL_RECORDS";
export const APPROVAL_RELOAD = "APPROVAL_RELOAD";
export const EVENTS_TOTAL_RECORDS = "EVENTS_TOTAL_RECORDS";
export const DASHBOARD_TOTAL_RECORDS = "DASHBOARD_TOTAL_RECORDS";
export const EVENTS_RELOAD = "EVENTS_RELOAD";
export const COMPANY_COMPLIANCE_TOTAL_RECORDS =
  "COMPANY_COMPLIANCE_TOTAL_RECORDS";
export const DIVISION_TOTAL_RECORDS = "DIVISION_TOTAL_RECORDS";
export const CERTIFICATE_TOTAL_RECORDS = "CERTIFICATE_TOTAL_RECORDS";
