import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  TOKEN_CHECK,
  TOKEN_CHECK_FAIL,
  LOGIN_ALERT_REMOVE,
  LOGOUT,
  RESET_FAILED,
  RESET_SUCCESS,
  GET_USER_DATA_BY_EMAIL,
  SET_LOADING_FALSE,
  SET_LOGOUT_PROVISION,
  SET_LOADING_TRUE,
  RESET_LOGIN_FAILED,
  OTP_SETTING,
  CUSTOM_USER,
  OTP_FALSE,
  SET_DIVISION_ROLE,
  SET_COMPANY_ROLE,
  IDLE_TIMEOUT,
  SET_OTP_TIMEOUT,
  OTP_FREEZE,
  SET_CHECK_TOKEN_BOOL,
  RESET_IDLE_TIME,
  LIST_OF_ACTIVE_PROCESS,
} from "../../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  loading: true,
  isError: false,
  isErrorType: null,
  errMessage: null,
  data: {},
  UserByEmail: {},
  setLogoutProvision: false,
  isOtp: false,
  customeUserRole: null,
  divisionRole: null,
  companyRole: null,
  idleTimeOut: null,
  otpTimeOut: 0,
  otpFreeze: false,
  checkToken: false,
  resetTIme: false,
  activeProcesses: [],
};

function authReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LIST_OF_ACTIVE_PROCESS:
      return {
        ...state,
        activeProcesses: payload,
      };
    case RESET_IDLE_TIME:
      return {
        ...state,
        resetTIme: payload,
      };
    case SET_CHECK_TOKEN_BOOL:
      return {
        ...state,
        checkToken: payload,
      };
    case OTP_FREEZE:
      return {
        ...state,
        otpFreeze: payload,
      };
    case SET_OTP_TIMEOUT:
      return {
        ...state,
        otpTimeOut: payload,
      };
    case IDLE_TIMEOUT:
      return {
        ...state,
        idleTimeOut: payload,
      };
    case SET_DIVISION_ROLE:
      return {
        ...state,
        divisionRole: payload,
      };
    case SET_COMPANY_ROLE:
      return {
        ...state,
        companyRole: payload,
      };
    case CUSTOM_USER:
      return {
        ...state,
        customeUserRole: payload,
      };
    case RESET_LOGIN_FAILED:
      return {
        ...state,
        isError: false,
        isErrorType: "",
        errMessage: "",
      };
    case TOKEN_CHECK:
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
        data: {
          company_id: payload.company_id,
          email: localStorage.email,
          name: localStorage.name,
          role: localStorage.role,
        },
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case OTP_SETTING:
      localStorage.setItem("company_id", payload.company_id);
      localStorage.setItem("email", payload.email);
      localStorage.setItem("name", payload.name);
      localStorage.setItem("role", payload.role);
      return {
        ...state,
        isAuthenticated: false,
        isOtp: true,
        loading: false,
        token: payload.token,
      };
    case OTP_FALSE:
      return {
        ...state,
        isOtp: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        isAuthenticated: true,
        loading: true,
        token: payload.token,
        isOtp: false,
      };
    case LOGIN_FAILED:
      // localStorage.clear();
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        isError: true,
        isErrorType: payload.hasOwnProperty("type")
          ? payload.type
          : "LOGIN_FAILED",
        errMessage: payload.message,
        isOtp: false,
      };
    case SET_LOGOUT_PROVISION:
      return {
        ...state,
        setLogoutProvision: true,
      };
    case TOKEN_CHECK_FAIL:
    case LOGOUT:
    case LOGIN_ALERT_REMOVE:
      let params = JSON.parse(localStorage.getItem("paramsListData"));
      localStorage.clear();
      if (params)
        localStorage.setItem("paramsListData", JSON.stringify(params));
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        isError: false,
        isErrorType: null,
        errMessage: null,
        data: {},
        UserByEmail: {},
        setLogoutProvision: false,
        customeUserRole: null,
      };
    case RESET_FAILED:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: true,
        isError: true,
        isErrorType: "RESET_FAILED",
        errMessage: payload.message,
      };
    case RESET_SUCCESS:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: true,
        isError: true,
        isErrorType: "RESET_SUCCESS",
        errMessage: payload.message,
      };
    case GET_USER_DATA_BY_EMAIL:
      return {
        ...state,
        UserByEmail: payload.data,
      };
    default:
      return state;
  }
}

export default authReducer;
